<template>
<main class="content container">
  <article class="order">
    <h1 class="page-title text-center">Adressdaten</h1>
    <Address :address="customerAddress">
      <h2 class="title-secondary bold-weight mb-3">Persönliche Daten</h2>
    </Address>
    <!--step order - additional address click on checkbox-->
    <section class="order__section">
      <Address :address="deliveryAddress" :optional="true" :optional-flag="differentDeliveryAddress">
        <div class="form-check form-switch mb-3">
          <input class="form-check-input" type="checkbox" id="showOrderStep" @change="onToggleDifferentAddress" :checked="differentDeliveryAddress">
          <label class="form-check-label title-secondary bold-weight" for="showOrderStep">Abweichende Lieferanschrift</label>
        </div>
      </Address>
    </section>
    <!--step order-->
    <section class="order__section">
      <Other :additional="customerAdditional"/>
      <div class="row mt-4">
        <div class="w-auto mb-2">
          <router-link :to="{ name: 'cart' }" class="btn btn-outline-primary min-width-170">Zurück</router-link>
        </div>
        <div class="w-auto mb-2">
          <button class="btn btn-primary min-width-170" type="button" @click="nextStep">weiter zu den Zahlungsmethoden</button>
        </div>
      </div>
    </section>
    <div class="row">
      <div v-if="error" class="col-md-12 alert alert-danger">{{ error }}</div>
    </div>
  </article>
  <InfoBox />
</main>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useMeta } from 'vue-meta'
import { useStore } from 'vuex'
import InfoBox from './cart/infoBox'
import Address from './customer/address'
import Other from './customer/other'
import { useRouter } from 'vue-router'
export default {
  components: {
    InfoBox,
    Address,
    Other
  },
  setup () {
    // meta
    useMeta({
      title: 'Adressdaten'
    })
    // store
    const store = useStore()
    const error = ref(null)
    // router
    const router = useRouter()
    // computed
    const customerAddress = computed(() => {
      return store.getters['checkout/customerAddress']
    })
    const deliveryAddress = computed(() => {
      return store.getters['checkout/deliveryAddress']
    })
    const differentDeliveryAddress = computed(() => {
      return store.getters['checkout/differentDeliveryAddress']
    })
    const customerAdditional = computed(() => {
      return store.getters['checkout/customerAdditional']
    })
    // actions
    function onToggleDifferentAddress (e) {
      store.commit('checkout/TOGGLE_DIFFERENT_ADDRESS', e.target.value)
    }
    function nextStep () {
      // checking fields
      const errors = []
      const address = store.getters['checkout/customerAddress']
      for (const key in address) {
        if (key !== 'company') {
          if (address[key] === '') {
            errors.push(key)
          }
        }
      }
      if (errors.length > 0) {
        error.value = 'Es wurde ein Feld nicht ausgefüllt bitte überprüfen Sie Ihre Angaben'
        setTimeout(() => {
          error.value = ''
        }, 3000)
      } else {
        router.push({ name: 'checkout.payment' })
      }
    }
    return {
      error,
      customerAddress,
      deliveryAddress,
      differentDeliveryAddress,
      customerAdditional,
      onToggleDifferentAddress,
      nextStep
    }
  }
}
</script>
