<template>
<section class="order__section">
  <slot></slot>
  <div v-if="showBody" class="row">
    <NameField type="text" name="name" :model="address.name" placeholder="Vor- & Nachname" :required="true" styleClass="col-md-4 col-sm-6 mb-3"/>
    <NameField type="text" name="phone" :model="address.phone" placeholder="Telefonnummer" :required="true" styleClass="col-md-4 col-sm-6 mb-3"/>
    <NameField type="email" name="email" :model="address.email" placeholder="Email" :required="true" styleClass="col-md-4 col-sm-6 mb-3"/>
    <NameField type="text" name="zip" :model="address.zip" placeholder="PLZ" :required="true" styleClass="col-xl-1 col-md-2 col-sm-3 mb-3"/>
    <NameField type="text" name="city" :model="address.city" placeholder="Stadt" :required="true" styleClass="col-xl-3 col-md-2 col-sm-3 mb-3"/>
    <NameField type="text" name="street" :model="address.street" placeholder="Strasse, Nr." :required="true" styleClass="col-md-4 mb-3 col-sm-6"/>
    <NameField type="text" name="company" :model="address.company" placeholder="Firma" :required="false" styleClass="col-md-4 col-sm-6 mb-3"/>
  </div>
  <div class="text-muted"><span class="color-orange">*</span> Pflichtangaben</div>
</section>
</template>
<script>
import { computed } from '@vue/reactivity'
import NameField from './nameField'
export default {
  components: {
    NameField
  },
  props: {
    title: String,
    address: Object,
    optional: {
      type: Boolean,
      default: false
    },
    optionalFlag: Boolean
  },
  setup (props) {
    const showBody = computed(() => {
      return !props.optional || (props.optional && props.optionalFlag)
    })
    return {
      showBody
    }
  }
}
</script>
