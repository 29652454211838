<template>
<div :class="styleClass">
  <input
  :type="type"
  class="form-control"
  :required="required"
  :placeholder="placeholder"
  :name="name"
  v-model="input"
  :class="[ error ? 'is-invalid' : '']"
  @input="setValue"
  >
  <div v-if="error" class="invalid-feedback">{{ error }}</div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  props: {
    styleClass: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    required: {
      default: false,
      type: Boolean
    },
    model: {
      require: true,
      type: String
    },
    name: {
      require: true,
      type: String
    }
  },
  setup (props) {
    const store = useStore()
    const address = computed(() => {
      return store.getters['checkout/customerAddress']
    })
    const input = ref(props.model)
    const error = computed(() => {
      if (props.required) {
        return input.value === '' ? props.placeholder + ' pflichtfeld' : ''
      }
    })
    function setValue (e) {
      address.value[e.target.name] = input.value
    }
    return {
      input,
      setValue,
      error
    }
  }
}
</script>
